<template>
  <div class="bottom">
    <div class="bottom_div">
      <div class="txt_top">
        <div class="left">
          <div class="img"><img src="../../assets/news.png" alt="" /></div>
          <div class="txt">
            今日专科医生就诊328人，预防疫苗108人，健康体检123人...
          </div>
        </div>
        <div class="right">首页 > 财务</div>
      </div>
      <div class="txt_news">
        <div class="txt_news_txt">订单管理</div>

        <div class="finance_div">
          <div class="finance_div_bot">导出</div>
          <div class="finance_div_bot">新增</div>
        </div>
      </div>
      <div class="txt_bottom">
        <div class="search_information">
          <div class="search_information_txt">
            <div class="search_information_img">
              <img src="../../assets/warn.png" alt="" />
            </div>
            <div class="search_information_txt">
              本次查询总额：36.4 万<span>清空</span>
            </div>
          </div>
        </div>
        <Finance />
      </div>
      <Page />
      <div class="txt_txt">
        <div class="txt_center">
          Copyright © 2020 亿立医疗顾问（深圳）有限公司 技术支持：中企高呈
        </div>
        <div class="txt_right">
          <div class="txt_right_txt"><a href="">联系客服</a></div>
          <div class="txt_right_txt"><a href="">帮助</a></div>
          <div class="txt_right_txt"><a href="">隐私</a></div>
          <div class="txt_right_txt"><a href="">条款</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Finance from "../../components/Finance.vue";
import Page from "../../components/Page.vue";
export default {
  name: "index",
  components: {
    Finance,
    Page,
  },
  data() {
    return {
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
      },
      list: [],
    };
  },
  mounted() {},
  // 函数
  methods: {},
};
</script>
<style lang="less">
@import "../../store/css/CSS-Initialization.css";
@import "../../store/css/general.less";
.txt_news {
  display: flex;
  border: 0;
  .txt_news_txt {
    padding-left: 14px;
    border-left: 3px solid #da8319;
    height: 18px;
    margin: auto 0;
  }
  div.finance_div {
    border: 0;
    display: flex;
    margin: 0;
    padding: 0;
    margin-left: auto;
    div.finance_div_bot {
      background-color: #e48a1b;
      color: white;
      font-size: 16px;
      padding: 8px 27px;
      margin-left: 12px;
      border-radius: 5px;
      letter-spacing: 4px;
    }
    div.finance_div_bot:last-child {
      background-color: #5f5f5f;
    }
  }
}
.search_information {
  display: flex;
  background-color: white;
  height: 48px;
  .search_information_txt {
    padding-left: 10px;
    display: flex;
    margin: auto 0;
    .search_information_img {
      margin: auto 0;
      display: flex;
      img {
        margin: auto 0;
      }
    }
    .search_information_txt {
      margin: auto 0;
      span {
        color: #e48a1b;
        margin-left: 24px;
      }
    }
  }
}
// .all .bottom .bottom_div div.txt_news {
//   border: 0;
//   padding: 0;
// }
</style>

