<template>
  <div class="finance_tab">
    <table>
      <th></th>
    </table>
  </div>
</template>

<script>
export default {
  name: "Finance",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.finance_tab{
  margin-top: 18px;
}
</style>
