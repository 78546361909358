<template>
  <div class="txt_bottom_page">
    <div class="page">
      <div class="page_div"><</div>
      <div class="page_div page_div_on">1</div>
      <div class="page_div">2</div>
      <div class="page_div">3</div>
      <div class="page_div">4</div>
      <div class="page_div">5</div>
      <div class="page_div">6</div>
      <div class="page_div">></div>
    </div>
    <div class="page_index">
      <p>跳至</p>
      <input type="text" />
      <p>页</p>
      <div>GO</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.txt_bottom_page {
  height: 80px;
  display: flex;
  background-color: white;
  .page {
    margin: auto 0;
    margin-left: auto;
    display: flex;
    .page_div {
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      margin: 0 4px;
      border: 1px solid #d9d9d9;
      color: #d9d9d9;
    }
    .page_div_on {
      color: white;
      background-color: #ea8400;
    }
  }
  .page_index {
    display: flex;
    height: 32px;
    line-height: 32px;
    margin: auto 0;
    font-size: 14px;
    color: #666666;
    input {
      width: 48px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      text-align: center;
    }
    div {
      background-color: #cbcbcb;
      color: white;
      width: 32px;
      border-radius: 4px;
    }
    * {
      margin: 0 4px;
    }
  }
}
</style>
